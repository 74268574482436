import React from "react";
import "../styles/Footer.scss";

const Footer = () => {
  return (
    <footer>
      <div className="footer-sections">
        <div>
          <a href="https://www.corporate.carrier.com/">
            <img
              src="https://images.carriercms.com/image/upload/w_150,q_100/v1573562016/common/logos/carrier-corp-logo.png"
              alt="Carrier Corporate Logo"
            />
          </a>
        </div>
        <div>
          <span>Legal Notice</span>
          {/* Add legal notice content here */}
        </div>
        <div>
          <span>Privacy Statement</span>
          {/* Add privacy statement content here */}
        </div>
        <div>
          <span>Terms &amp; Conditions</span>
          {/* Add terms and conditions content here */}
        </div>
        <div>
          <span>Contact</span>
          {/* Add contact information here */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
