import React, { useEffect, useState } from 'react';
import * as yaml from 'js-yaml';

interface SwaggerPath {
  path: string;
  method: string;
  description: string;
}

interface SwaggerProps {
  yamlFilePath: string;
  apiName: string;
}

interface Parameter {
  name: string;
  in: string;
  description?: string;
  required?: boolean;
  schema: {
    type: string;
  };
}

interface RequestBodySchema {
  [key: string]: any;
}

const Header: React.FC<{
  onAuthorizeClick: () => void;
  // onDownloadSpecClick: () => void;
}> = ({ onAuthorizeClick }) => {
  return (
    <header
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 20px',
        backgroundColor: '#02155f',
        color: 'white',
      }}
    >
      <h3 style={{ margin: 0 }}>Carrier LYNX Developer APIs</h3>
      <div>
        <button
          onClick={onAuthorizeClick}
          style={{
            marginRight: '10px',
            padding: '8px 16px',
            backgroundColor: 'white',
            border: 'none',
            fontWeight: 'bold',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          Authorize
        </button>
        <button
          // onClick={onDownloadSpecClick}
          style={{
            padding: '8px 16px',
            backgroundColor: 'white',
            border: 'none',
            fontWeight: 'bold',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          Download Spec
        </button>
      </div>
    </header>
  );
};

const SwaggerUI: React.FC<SwaggerProps> = ({ yamlFilePath, apiName }) => {
  const [swaggerData, setSwaggerData] = useState<any | null>(null);
  const [selectedPath, setSelectedPath] = useState<SwaggerPath | null>(null);
  const [selectedSchema, setSelectedSchema] = useState<string | null>(null);
  const [expandedSections, setExpandedSections] = useState<{
    [key: string]: boolean;
  }>({});
  const [error, setError] = useState<string | null>(null);
  const [apiKey, setApiKey] = useState<string>('');
  const [apiResponses, setApiResponses] = useState<
    Record<string, { status: number; data: any }>
  >({});
  const [queryParams, setQueryParams] = useState<Record<string, string>>({});
  const [isAuthModalOpen, setIsAuthModalOpen] = useState<boolean>(false);
  const [formData, setFormData] = useState<{ [key: string]: string }>({});
  const [requestBodySchema, setRequestBodySchema] =
    useState<RequestBodySchema | null>(null);
  const [isRequestBodyExpanded, setIsRequestBodyExpanded] =
    useState<boolean>(false);

  // Load and parse YAML to JSON
  useEffect(() => {
    const loadYamlFile = async () => {
      try {
        const response = await fetch(yamlFilePath);
        const yamlContent = await response.text();
        const jsonData = yaml.load(yamlContent);
        setSwaggerData(jsonData);
      } catch (err) {
        console.error('Failed to load or parse YAML file:', err);
        setError('Failed to load or parse the YAML file.');
      }
    };

    loadYamlFile();
  }, [yamlFilePath]);

  // Toggle sections (expand/collapse)
  const toggleSection = (section: string) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  // Handle API Path Selection
  const handlePathSelect = (path: SwaggerPath) => {
    setSelectedPath(path);
    setSelectedSchema(null);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setQueryParams((prev) => ({ ...prev, [name]: value }));
  };

  // Handle Schema Selection
  const handleSchemaSelect = (schema: string) => {
    setSelectedSchema(schema);
    setSelectedPath(null);
  };

  const handleAuthorizeClick = () => {
    setIsAuthModalOpen(true);
  };

  const handleApiKeySubmit = () => {
    console.log(`API Key Set: ${apiKey}`);
    setIsAuthModalOpen(false);
  };

  const getMethodColor = (method: string) => {
    switch (method.toLowerCase()) {
      case 'get':
        return '#0a9970';
      case 'delete':
        return 'red';
      case 'post':
        return '#0071c2';
      case 'put':
        return '#ffc379';
      default:
        return 'gray';
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!selectedPath || !swaggerData) {
      alert('Invalid path or Swagger data.');
      return;
    }

    const baseUrl = swaggerData.servers?.[0]?.url || '';
    const endpoint = `${baseUrl}${selectedPath.path}`;
    const urlWithParams = new URL(endpoint);

    Object.entries(queryParams).forEach(([key, value]) => {
      if (value.trim() !== '') {
        urlWithParams.searchParams.append(key, value);
      }
    });

    const params =
      selectedPath.method.toUpperCase() !== 'GET'
        ? JSON.stringify(queryParams)
        : undefined;

    try {
      const response = await fetch(urlWithParams.toString(), {
        method: selectedPath.method.toUpperCase(),
        headers: {
          'Content-Type': 'application/json',
          'x-lynx-api-key': apiKey,
        },
        body: params,
      });

      const responseData = await response.json();

      // Update the state only for the current API path
      setApiResponses((prev) => ({
        ...prev,
        [selectedPath.path]: {
          status: response.status,
          data: responseData,
        },
      }));
    } catch (error: any) {
      setApiResponses((prev) => ({
        ...prev,
        [selectedPath.path]: {
          status: 500,
          data: { error: error.message },
        },
      }));
    }
  };

  if (error) {
    return <div style={{ color: 'red' }}>{error}</div>;
  }

  if (!swaggerData) {
    return <div>Loading...</div>;
  }

  // Group APIs by version (e.g., "v1", "v2")
  const groupedByVersion: { [version: string]: SwaggerPath[] } = {};

  Object.entries(swaggerData.paths || {}).forEach(([path, methods]: any) => {
    const versionMatch = path.match(/^\/(v\d+)\//); // Match version prefix (e.g., "/v1/")
    const version = versionMatch ? versionMatch[1] : 'default';

    Object.entries(methods).forEach(([method, details]: any) => {
      const apiDetails = {
        path,
        method,
        description: details.summary || 'No description available',
      };

      if (!groupedByVersion[version]) {
        groupedByVersion[version] = [];
      }
      groupedByVersion[version].push(apiDetails);
    });
  });

  const schemas = swaggerData.components?.schemas || {};
  const url = swaggerData.servers[0].url;

  return (
    <>
      <Header
        onAuthorizeClick={handleAuthorizeClick}
        // onDownloadSpecClick={handleDownloadSpec}
      />

      {isAuthModalOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '8px',
              boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
              width: '400px',
              textAlign: 'center',
            }}
          >
            <h2>Enter API Key</h2>
            <input
              type='text'
              placeholder='Enter x-lynx-api-key'
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
              style={{
                width: '100%',
                padding: '8px',
                margin: '10px 0',
                borderRadius: '4px',
                border: '1px solid #ccc',
              }}
            />
            <button
              onClick={handleApiKeySubmit}
              style={{
                padding: '8px 16px',
                backgroundColor: '#02155f',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                marginRight: '10px',
              }}
            >
              Save
            </button>
            <button
              onClick={() => setIsAuthModalOpen(false)}
              style={{
                padding: '8px 16px',
                backgroundColor: '#02155f',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      <div
        className='swagger-ui-container'
        style={{ display: 'flex', height: '100vh' }}
      >
        {/* Left Panel: Scrollable API Paths and Schemas */}
        <div
          style={{
            width: '20%',
            borderRight: '1px solid #ccc',
            overflowY: 'auto',
            padding: '10px',
          }}
        >
          <h4 style={{
                  cursor: 'pointer',
                  backgroundColor: '#f4f4f4',
                  padding: '10px',
                  borderRadius: '4px',
                }}>Overview</h4>
          {/* <h3>{apiName}</h3> */}

          {/* Grouped APIs by Version */}
          {Object.entries(groupedByVersion).map(([version, paths]) => (
            <div key={version} style={{ marginBottom: '20px' }}>
              <h4
                style={{
                  cursor: 'pointer',
                  backgroundColor: '#f4f4f4',
                  padding: '10px',
                  borderRadius: '4px',
                }}
                onClick={() => toggleSection(version)}
              >
                {expandedSections[version] ? '▼' : '▶'} /{version}/{' '}
              </h4>
              {expandedSections[version] && (
                <ul style={{ listStyle: 'none', padding: '10px 0 0 10px' }}>
                  {paths.map((path, index) => (
                    <li
                      key={index}
                      style={{
                        padding: '5px 0',
                        cursor: 'pointer',
                        backgroundColor:
                          selectedPath?.path === path.path &&
                          selectedPath?.method === path.method
                            ? '#f0f0f0'
                            : 'transparent',
                      }}
                      onClick={() => handlePathSelect(path)}
                    >
                      {path.path.replace(/^\/(v\d+)\//, '').replace(/^\//, '')}{' '}
                      <span
                        style={{
                          marginLeft: '8px',
                          padding: '2px 6px',
                          borderRadius: '4px',
                          color: 'white',
                          fontWeight: 'bold',
                          fontSize: '12px',
                          backgroundColor: getMethodColor(path.method),
                        }}
                      >
                        {path.method.toUpperCase()}
                      </span>
                      {/* Remove version prefix for display */}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}

          {/* Display schemas with expandable/collapsible section */}
          <div>
            <h4
              style={{
                cursor: 'pointer',
                backgroundColor: '#f4f4f4',
                padding: '10px',
                borderRadius: '8px',
              }}
              onClick={() => toggleSection('schemas')}
            >
              {expandedSections['schemas'] ? '▼' : '▶'} Schemas
            </h4>
            {expandedSections['schemas'] && (
              <ul style={{ listStyle: 'none', padding: '10px 0 0 10px' }}>
                {Object.keys(schemas).map((schemaName, index) => (
                  <li
                    key={index}
                    style={{
                      padding: '5px 0',
                      cursor: 'pointer',
                      backgroundColor:
                        selectedSchema === schemaName
                          ? '#f0f0f0'
                          : 'transparent',
                    }}
                    onClick={() => handleSchemaSelect(schemaName)}
                  >
                    {schemaName}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        {/* Middle Panel: API Details */}
        <div
          style={{
            width: '50%',
            padding: '20px',
            overflowY: 'auto',
            backgroundColor: '#f9f9f9',
            borderRadius: '8px',
          }}
        >
          {selectedPath ? (
            <div>
              {/* Method and Endpoint Header */}
              <div style={{ marginBottom: '20px' }}>
                <h2 style={{ display: 'flex', alignItems: 'center' }}>
                  {selectedPath.path}
                </h2>
                <p
                  style={{ fontSize: '14px', color: '#555', marginTop: '8px' }}
                >
                  <span
                    style={{
                      marginRight: '8px',
                      padding: '6px 10px',
                      borderRadius: '4px',
                      color: 'white',
                      fontWeight: 'bold',
                      fontSize: '10px',
                      backgroundColor: getMethodColor(selectedPath.method),
                    }}
                  >
                    {selectedPath.method.toUpperCase()}
                  </span>{' '}
                  {url}
                  {selectedPath.path}
                </p>
              </div>

              {/* API Description */}
              <div style={{ marginBottom: '20px' }}>
                <p style={{ fontSize: '14px', lineHeight: '1.6' }}>
                  {swaggerData.paths[selectedPath.path][selectedPath.method]
                    .description || 'No description available.'}
                </p>
              </div>
              <hr
                style={{
                  border: 'none',
                  borderTop: '1px solid #ddd',
                  margin: '20px 0',
                }}
              />

              {/* Parameters Section */}
              {swaggerData.paths[selectedPath.path][selectedPath.method]
                .parameters?.length > 0 && (
                <div>
                  {/* Path Parameters */}
                  {swaggerData.paths[selectedPath.path][
                    selectedPath.method
                  ].parameters.some((param: any) => param.in === 'path') && (
                    <div style={{ marginBottom: '20px' }}>
                      <h4>Path Parameters</h4>
                      {swaggerData.paths[selectedPath.path][
                        selectedPath.method
                      ].parameters
                        .filter((param: any) => param.in === 'path')
                        .map((param: any, index: number) => (
                          <div key={index} style={{ marginBottom: '12px' }}>
                            <strong>{param.name}:</strong>{' '}
                            <span>{param.schema.type}</span>
                            <p>
                              {param.description || 'No description provided.'}
                            </p>
                          </div>
                        ))}
                    </div>
                  )}

                  {swaggerData.paths[selectedPath.path][
                    selectedPath.method
                  ].parameters.some((param: any) => param.in === 'query') && (
                    <div style={{ marginBottom: '20px' }}>
                      <h4 style={{ marginBottom: '10px' }}>Query Parameters</h4>
                      {swaggerData.paths[selectedPath.path][
                        selectedPath.method
                      ].parameters.filter((param: any) => param.in === 'query')
                        .length > 0 ? (
                        <div
                          style={{
                            border: '1px solid #ddd',
                            borderRadius: '8px',
                            padding: '16px',
                            backgroundColor: '#fff',
                          }}
                        >
                          {swaggerData.paths[selectedPath.path][
                            selectedPath.method
                          ].parameters
                            .filter((param: any) => param.in === 'query')
                            .map((param: any, index: number) => (
                              <div
                                key={index}
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  borderBottom:
                                    index !==
                                    swaggerData.paths[selectedPath.path][
                                      selectedPath.method
                                    ].parameters.filter(
                                      (param: any) => param.in === 'query'
                                    ).length -
                                      1
                                      ? '1px solid #eee'
                                      : 'none',
                                  paddingBottom: '12px',
                                  marginBottom: '12px',
                                }}
                              >
                                {/* Parameter Name and Required Indicator */}
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '8px',
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: 'bold',
                                      fontSize: '16px',
                                      marginRight: '8px',
                                    }}
                                  >
                                    {param.name}
                                  </span>
                                  <span
                                    style={{
                                      fontSize: '16px',
                                      marginRight: '8px',
                                      color: 'gray',
                                    }}
                                  >
                                    {param.schema.type}
                                  </span>
                                  <span
                                    style={{
                                      fontSize: '12px',
                                      color: param.required ? 'red' : '#555',
                                      backgroundColor: param.required
                                        ? '#ffecec'
                                        : '',
                                      borderRadius: '4px',
                                      padding: '2px 8px',
                                    }}
                                  >
                                    {param.required ? 'required' : ''}{' '}
                                    {/* specify optional param here */}
                                  </span>
                                </div>

                                {/* Parameter Description */}
                                <p
                                  style={{
                                    fontSize: '14px',
                                    color: '#555',
                                    lineHeight: '1.4',
                                    marginBottom: '8px',
                                  }}
                                >
                                  {param.description ||
                                    'No description provided.'}
                                </p>
                              </div>
                            ))}
                        </div>
                      ) : (
                        <p style={{ fontSize: '14px', color: '#777' }}>
                          No query parameters available for this endpoint.
                        </p>
                      )}
                    </div>
                  )}
                </div>
              )}

              {/* Request Body Section */}
              {swaggerData.paths[selectedPath.path][selectedPath.method]
                .requestBody && (
                <div style={{ marginBottom: '20px' }}>
                  <h4 style={{ marginTop: '20px', marginBottom: '10px' }}>
                    Request Body
                  </h4>
                  {swaggerData.paths[selectedPath.path][selectedPath.method]
                    .requestBody ? (
                    <div
                      style={{
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        padding: '16px',
                        backgroundColor: '#fff',
                      }}
                    >
                      <pre
                        style={{
                          backgroundColor: '#f4f4f4',
                          padding: '12px',
                          borderRadius: '4px',
                          fontSize: '14px',
                        }}
                      >
                        {JSON.stringify(
                          swaggerData.paths[selectedPath.path][
                            selectedPath.method
                          ].requestBody,
                          null,
                          2
                        )}
                      </pre>
                    </div>
                  ) : (
                    <p style={{ fontSize: '14px', color: '#777' }}>
                      No request body available for this endpoint.
                    </p>
                  )}
                </div>
              )}

              {/* Combination of Path Parameters & Request Body */}
              {swaggerData.paths[selectedPath.path][
                selectedPath.method
              ].parameters?.some((param: any) => param.in === 'path') &&
                swaggerData.paths[selectedPath.path][selectedPath.method]
                  .requestBody && (
                  <div
                    style={{
                      marginTop: '20px',
                      padding: '10px',
                      backgroundColor: '#ffeccc',
                      borderRadius: '8px',
                    }}
                  >
                    <strong>Note:</strong> This API requires both{' '}
                    <em>Path Parameters</em> and a <em>Request Body</em>.
                  </div>
                )}
            </div>
          ) : selectedSchema ? (
            <div>
              <h4>Schema: {selectedSchema}</h4>
              <pre
                style={{
                  backgroundColor: '#f4f4f4',
                  padding: '10px',
                  borderRadius: '4px',
                }}
              >
                {JSON.stringify(schemas[selectedSchema], null, 2)}
              </pre>
            </div>
          ) : (
            <p>Select an API path or schema to view details.</p>
          )}
        </div>

        {/* Right Panel: Try API */}
        <div
          style={{
            width: '30%',
            padding: '20px',
            borderLeft: '1px solid #ccc',
            overflowY: 'auto',
            backgroundColor: '#f0f0f0',
          }}
        >
          {selectedPath ? (
            <div>
              {/* Try API Section */}
              <div>
                <h4>Try this API</h4>
                <form onSubmit={handleSubmit}>
                  {(
                    swaggerData.paths[selectedPath.path][selectedPath.method]
                      .parameters || []
                  ).map((param: Parameter, index: number) => (
                    <div key={index} style={{ marginBottom: '15px' }}>
                      <label>
                        {param.name} {param.required ? 'required' : ''}
                        <input
                          type='text'
                          name={param.name}
                          placeholder={param.schema.type}
                          style={{
                            display: 'block',
                            width: '100%',
                            padding: '8px',
                            marginTop: '5px',
                            borderRadius: '4px',
                            border: '1px solid #ccc',
                          }}
                          onChange={handleInputChange}
                        />
                      </label>
                    </div>
                  ))}
                  <button
                    type='submit'
                    style={{
                      backgroundColor: '#02155f',
                      color: 'white',
                      padding: '8px 16px',
                      borderRadius: '4px',
                      border: 'none',
                      cursor: 'pointer',
                      fontWeight: 'bold',
                    }}
                  >
                    EXECUTE
                  </button>
                </form>
              </div>
              {/* API Response Section */}
              {selectedPath && apiResponses[selectedPath.path] && (
                <div
                  style={{
                    marginTop: '20px',
                    borderTop: '1px solid #ddd',
                    paddingTop: '10px',
                  }}
                >
                  <h4>Response</h4>
                  <p>
                    <strong>Status:</strong>{' '}
                    {apiResponses[selectedPath.path].status}
                  </p>
                  <pre
                    style={{
                      backgroundColor: '#f8f9fa',
                      padding: '15px',
                      borderRadius: '4px',
                      border: '1px solid #ccc',
                      overflowX: 'auto',
                    }}
                  >
                    {JSON.stringify(
                      apiResponses[selectedPath.path].data,
                      null,
                      2
                    )}
                  </pre>
                </div>
              )}
            </div>
          ) : (
            <p>Select an API path to try it.</p>
          )}
        </div>
      </div>
    </>
  );
};

export const CustomSwagger: React.FC<SwaggerProps> = (props) => {
  return <SwaggerUI {...props} />;
};

export default CustomSwagger;
