import React from "react";
import { Box } from "@mui/material";
import "../styles/GettingStartedPage.scss";

const listItems = [
  {
    id: 1,
    heading: "Register to LYNX API Portal",
    description:
      "Sign up for a LYNX API Portal account to explore the technical details of LYNX APIs and try them out in our Sandbox. You can get to the registration form by clicking on the Register button in the top right corner.",
  },
  {
    id: 2,
    heading: "Activate your account",
    description:
      "You will receive an activation link via email. Follow the instructions to activate our account.",
  },
  {
    id: 3,
    heading: "Sign into your account",
    description:
      "Take advantage of our API documentation, developer guides and virtual building to create and test your smart solutions.",
  },
  {
    id: 4,
    heading: "Access to LYNX equipment and data through APIs",
    description: (
      <>
        <p>
          LYNX API Services add value to LYNX equipment and maintenance service.
        </p>
        <p>
          Access to LYNX data or equipment through LYNX APIs is available for
          LYNX maintenance service customers with LYNX API Services contract.
        </p>
        <p>
          Interested in becoming LYNX customer and enjoying the benefits of LYNX
          APIs? Contact our local LYNX sales to hear more about the available
          LYNX API Services offering and pricing.
        </p>
      </>
    ),
  },
];

interface ListItem {
  id: number;
  heading: string;
  description: string | React.ReactNode;
}

const ListItems = ({ items }: { items: ListItem[] }) => {
  return (
    <>
      {items.map((item) => (
        <Box key={item.id} className="list-items">
          <Box className="list-order-item">
            <h1>{item.id}</h1>
          </Box>
          <Box className="list-header">
            <p className="list-heading">{item.heading}</p>
            <p className="list-subheading">{item.description}</p>
          </Box>
        </Box>
      ))}
    </>
  );
};

const GettingStartedPage = () => {
  return (
    <>
      <Box className="container-getting-started">
        {/* <h1 className="heading">Get started</h1>
        <p className="subheading">
          Register now and get access to our APIs and test environment
        </p> */}
      </Box>

      <ListItems items={listItems} />
    </>
  );
};

export default GettingStartedPage;
