import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./components/HomePage";
import ProductPage from "./components/ProductPage";
import Login from "./components/Login";
import CustomSwagger from "./components/CustomSwagger";
// import NotFoundPage from "./components/NotFoundPage";
import CustomSwaggerNew from "./components/CustomSwaggerNew";

const AppRoutes: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/products" element={<ProductPage />} />
        <Route
          path="/products/tti-one-way"
          element={<CustomSwagger url="/api-specs/external-rest-api.yml"  apiName="TTI One Way API"/>}
        />
        {/* <Route path="/swagger" element={<CustomSwaggerNew  yamlFilePath="/api-specs/external-rest-api.yml"  apiName="Carrier LYNX Developer APIs"/>} /> */}
        <Route path="*" element={<CustomSwaggerNew  yamlFilePath="/api-specs/external-rest-api.yml"  apiName="Carrier LYNX Developer APIs"/>} />{" "}
      </Routes>
    </Router>
  );
};

export default AppRoutes;
