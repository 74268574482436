import React from 'react';
import '../styles/ProductCard.scss';

interface ProductCardProps {
  item: {
    id: string;
    title: string;
    image: string;
    handleClick: () => void;
  };
}

const ProductCard: React.FC<ProductCardProps>  = ({ item }) => {
  const { title, image, handleClick } = item;
  return (
    <div className="product-card" onClick={handleClick}>
      <img src={image} alt={title} className="product-image" />
      <div className="product-info">
        <h3 className="product-title">{title}</h3>
      </div>
    </div>
  );
};

export default ProductCard;
