interface ProductMappingItem {
  title: string;
  route: string;
  description: string;
}

interface ProductMapping {
  [key: string]: ProductMappingItem;
}

export const productMapping: ProductMapping = {
  "TTI|1W": {
    title: "External Rest APIs",
    route: "tti-one-way",
    description:
      "Track real-time location, status, and diagnostic information of vehicles, enabling efficient fleet management.",
  },
  "TTI|2W": {
    title: "2 Way Command APIs",
    route: "tti-two-way",
    description:
      "Plan, optimize, and dynamically adjust routes based on real-time conditions, ensuring efficient transportation.",
  },
};
