import "../styles/LynxApiPage.scss";
import { Box } from "@mui/material";
import ApiSection from "./ApiSection";
import { apiCardsDetail } from "../utils/ApiCardDetails";

const LynxApiPage = () => {
  return (
    <>
      <Box className="container-lynx-apis">
        <p className="subheading" color="#4994ec">
          Carrier's Transport Refrigeration API Solutions Across BU's
        </p>
      </Box>
      {apiCardsDetail.map((data, index) => (
        <ApiSection
          key={index}
          title={data.title}
          subtitle={data.subtitle}
          description={data.description}
          buttonText={data.buttonText}
          cardData={data.cardData}
          imageIndex={data.imageIndex}
        />
      ))}
    </>
  );
};

export default LynxApiPage;
