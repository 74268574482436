export const apiCardsDetail = [
  {
    title: "TTI Refrigeration APIs",
    subtitle: "Streamline your international fleet operations",
    description:
      "With our APIs, you can create new solutions to streamline your international truck and trailer operations. Integrate with our systems to enable features like remote monitoring, predictive maintenance, and route optimization for your global fleet.",
    buttonText: "Explore TTI API Products",
    cardData: [],
    imageIndex: 0,
  },
  {
    title: "Container Business APIs",
    subtitle: "Ensure cost-efficiency and operational excellence",
    description:
      "With our Maritime Container APIs, you can integrate with our systems to gain insights and control over your sea logistics operations. Track container movements, optimize routes, and manage shipments more efficiently.",
    buttonText: "Explore Container API Products",
    cardData: [],
    imageIndex: 1,
  },
  {
    title: "TTA Refrigeration APIs",
    subtitle: "Optimize your American fleet operations",
    description:
      "With our APIs, you can create new solutions to streamline your truck and trailer operations in North America. Integrate with our systems to enable features like remote monitoring, predictive maintenance, and route optimization for your regional fleet.",
    buttonText: "Explore TTA API Products",
    cardData: [],
    imageIndex: 2,
  },
  {
    title: "SensiTech APIs",
    subtitle: "Stay informed with real-time updates",
    description:
      "Sensitech is a global leader in delivering supply chain visibility solutions. Our innovative monitoring products and services help to maintain the quality, integrity and security of our customers' valuable products at every step in their journey, all around the world.",
    buttonText: "Explore Sensitech API Products",
    cardData: [],
    imageIndex: 3,
  },
];
