import { useState } from "react";
import { Box } from "@mui/material";
import InspirationPage from "./InspirationPage";
import GettingStartedPage from "./GettingStartedPage";
import LynxApiPage from "./LynxApiPage";
import "../styles/HomePage.scss";
import Footer from "./Footer";
import Header from "./Header";
import Navbar from "./NavBar";

const HomePage = () => {
  const [selectedNav, setSelectedNav] = useState<string>("lynx-apis");

  const handleNavClick = (nav: string) => {
    setSelectedNav(nav);
  };

  return (
    <Box className="page-container">
      <Header title={""}/>

      <Navbar selectedNav={selectedNav} handleNavClick={handleNavClick} />

      {/* {selectedNav === "inspiration" && <InspirationPage />} */}
      {selectedNav === "lynx-apis" && <LynxApiPage />}
      {selectedNav === "getting-started" && <GettingStartedPage />}
      

      <Footer />
    </Box>
  );
};

export default HomePage;
