import { Box, Button, Typography, useTheme } from "@mui/material";
import "../styles/Navbar.scss";
import { useState } from "react";
import SlidingPanel from "./SlidingPanel";
import { useNavigate } from "react-router-dom";

interface NavbarProps {
  selectedNav: string;
  handleNavClick: (nav: string) => void;
}

const Navbar: React.FC<NavbarProps> = ({ selectedNav, handleNavClick }) => {
  const theme = useTheme();
  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
  const navigate = useNavigate();
  const primaryColor = theme.palette.primary.main;
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const togglePanel = () => {
    setIsPanelOpen(!isPanelOpen);
  };

  const handlePanelClose = () => {
    setIsPanelOpen(false);
  };

  return (
    <Box className="nav-container" sx={{ "--primary-color": primaryColor }}>
      {/* <Box>
        <Box
          className={`nav-item ${selectedNav === "inspiration" ? "nav-item-active" : ""}`}
        >
          <Typography
            onClick={() => handleNavClick("inspiration")}
            className="nav-item-text"
          >
            Inspiration
          </Typography>
        </Box>
      </Box> */}
      <Box>
        <Box
          className={`nav-item ${selectedNav === "lynx-apis" ? "nav-item-active" : ""}`}
        >
          <Typography
            onClick={() => handleNavClick("lynx-apis")}
            className="nav-item-text"
          >
            LYNX APIs
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box
          className={`nav-item ${selectedNav === "getting-started" ? "nav-item-active" : ""}`}
        >
          <Typography
            onClick={() => handleNavClick("getting-started")}
            className="nav-item-text"
          >
            Getting Started
          </Typography>
        </Box>
      </Box>
      {!isLoggedIn && (
        <Box className="register-login">
          <Button className="nav-button" onClick={togglePanel} sx={{ pr: 5 }}>
            Log in
          </Button>
          <Button
            className="nav-button nav-button-outlined"
            onClick={togglePanel}
          >
            Register
          </Button>
        </Box>
      )}
      {isLoggedIn && (
        <Box className="navbar-right" onClick={() => navigate("/products")}>
          <Typography className="nav-button" sx={{ cursor: "pointer" }}>
            {" "}
            API PRODUCTS
          </Typography>
          <Box className="navbar-back-arrow"></Box>
        </Box>
      )}
      <SlidingPanel openPanel={isPanelOpen} handleClose={handlePanelClose} />
    </Box>
  );
};

export default Navbar;
